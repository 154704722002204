import { createMemo, createSignal, For, Match, Show, Switch } from "solid-js";
import { useFastingService } from "../fasting.service";
import { ModalFactory } from "@client/shared/ui";

export function FastRecordList() {
  const [isEditMode, setIsEditMode] = createSignal(false);
  const toggleEditMode = () => setIsEditMode(!isEditMode());
  const [selectedIndexes, setSelectedIndexes] = createSignal<number[]>([]);

  const { isFasting, recordList, deleteRecords, endTime, updateRecord } =
    useFastingService();
  const [selectedFastRecordIndex, setSelectedFastRecordIndex] =
    createSignal<number>(Number.MIN_SAFE_INTEGER);
  const selectedFastRecord = () =>
    recordList().find((_, index) => index === selectedFastRecordIndex());

  const WarningModal = ModalFactory.create();
  const FastRecordEditModal = ModalFactory.create();

  const flooredTime = (time: number) => time - (time % 1000);
  const fastedTime = (startTime: number, endTime: number) => {
    const fastedTime = flooredTime(endTime) - flooredTime(startTime);
    const SECOND = 1000;
    const MINUTE = SECOND * 60;
    const HOUR = 60 * MINUTE;

    const hours = Math.floor(fastedTime / HOUR);
    const minutes = Math.floor((fastedTime % HOUR) / MINUTE);

    return `${hours.toString().padStart(2, "0")}h ${minutes
      .toString()
      .padStart(2, "0")}m`;
  };

  const dateTime = (time: number) => {
    return new Intl.DateTimeFormat(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(time);
  };

  const sumIndexesOfArray = (arrayLength: number) =>
    ((1 + arrayLength) * arrayLength) / 2;

  const getDateTimeLocalInputValue = (time: number) => {
    const date = new Date(time);

    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
  };

  return (
    <article class="max-w-3xl m-auto">
      <div
        class="w-full h-12 flex justify-between items-center"
        classList={{
          "justify-between": !isEditMode() || recordList().length > 0,
          "justify-end": isEditMode() && recordList().length === 0,
        }}
      >
        <Switch>
          <Match when={!isEditMode() && recordList().length > 0}>
            <h2 class="text-3xl font-bold">Fast Records</h2>

            <button class="btn btn-neutral btn-md" onClick={toggleEditMode}>
              Edit
            </button>
          </Match>

          <Match when={isEditMode()}>
            <Show when={recordList().length > 0}>
              <label class="flex items-center gap-1">
                <input
                  type="checkbox"
                  class="checkbox checkbox-success"
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setSelectedIndexes([]);
                      return;
                    }

                    setSelectedIndexes(
                      new Array(recordList().length).fill(null).map((_, i) => i)
                    );
                  }}
                  checked={
                    sumIndexesOfArray(selectedIndexes().length) ===
                    sumIndexesOfArray(recordList().length)
                  }
                />
                <Show
                  when={selectedIndexes().length === recordList().length}
                  fallback="Select all"
                >
                  Unselect all
                </Show>
              </label>
            </Show>

            <div class="flex gap-4">
              <button
                class="btn btn-error btn-md"
                onClick={WarningModal.open}
                disabled={selectedIndexes().length === 0}
              >
                Delete
              </button>

              <button
                class="btn btn-neutral btn-md"
                onClick={() => {
                  toggleEditMode();
                  setSelectedIndexes([]);
                }}
              >
                Done
              </button>
            </div>
          </Match>
        </Switch>

        <WarningModal>
          <p class="font-bold h-20">
            Do you want to delete selected fast records?
          </p>

          <div class="flex gap-2 mt-4 justify-end">
            <button class="btn btn-md" onClick={WarningModal.close}>
              Close
            </button>
            <button
              class="btn btn-error btn-md"
              onClick={() => {
                if (selectedIndexes().length === recordList().length) {
                  setIsEditMode(false);
                }
                deleteRecords(selectedIndexes());
                setSelectedIndexes([]);
                WarningModal.close();
              }}
            >
              Delete
            </button>
          </div>
        </WarningModal>
      </div>

      <ol class="mt-6 flex flex-col gap-4">
        <For each={recordList()}>
          {(item, index) => {
            const date = createMemo(
              () => new Date(index() > 0 ? item.endTime : endTime())
            );
            const dateQuery = () =>
              `${date().getFullYear()}-${(date().getMonth() + 1)
                .toString()
                .padStart(2, "0")}-${date()
                .getDate()
                .toString()
                .padStart(2, "0")}`;

            return (
              <li class="bg-neutral text-secondary-content rounded-box basis-40 flex items-center gap-4 p-2">
                <Show when={isEditMode()}>
                  <input
                    type="checkbox"
                    class="checkbox checkbox-success"
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setSelectedIndexes((state) =>
                          state.filter((a) => a !== index())
                        );
                        return;
                      }

                      setSelectedIndexes((state) => [...state, index()]);
                    }}
                    checked={selectedIndexes().includes(index())}
                  />
                </Show>

                <div class="flex flex-col gap-4 justify-center items-center grow">
                  <Switch>
                    <Match when={index() === 0 && isFasting()}>
                      <p class="text-xl font-bold">
                        {dateTime(item.startTime)}
                      </p>
                    </Match>

                    <Match
                      when={
                        (index() > 0 || !isFasting()) &&
                        item.endTime > Number.MIN_SAFE_INTEGER
                      }
                    >
                      <p class="text-3xl font-bold">
                        {fastedTime(item.startTime, item.endTime)}
                      </p>

                      <p class="text-sm text-center">
                        {dateTime(item.startTime)}
                        <br />
                        &darr;
                        <br />
                        {dateTime(item.endTime)}
                      </p>
                    </Match>
                  </Switch>
                </div>

                <Switch>
                  <Match
                    when={
                      item.endTime > Number.MIN_SAFE_INTEGER && !isEditMode()
                    }
                  >
                    <a class="btn" href={`/diets/diet-of-date/${dateQuery()}`}>
                      Diet
                    </a>
                  </Match>

                  <Match
                    when={
                      item.endTime > Number.MIN_SAFE_INTEGER && isEditMode()
                    }
                  >
                    <button
                      class="btn btn-info"
                      onClick={() => {
                        setSelectedFastRecordIndex(index());
                        FastRecordEditModal.open();
                      }}
                    >
                      Edit
                    </button>
                  </Match>
                </Switch>
              </li>
            );
          }}
        </For>
      </ol>

      <FastRecordEditModal>
        <form
          onSubmit={async (e) => {
            e.preventDefault();

            const formData = new FormData(e.currentTarget);
            const timezoneOffsetInMinutes = parseInt(
              (formData.get("timezone") ?? "") as string,
              10
            );
            const timezoneString =
              timezoneOffsetInMinutes === 0
                ? "Z"
                : `${timezoneOffsetInMinutes > 0 ? "+" : "-"}${Math.floor(
                    timezoneOffsetInMinutes / 60
                  )
                    .toString()
                    .padStart(2, "0")}:${(timezoneOffsetInMinutes % 60)
                    .toString()
                    .padStart(2, "0")}`;
            const startTimeInputValue = (formData.get("startTime") ??
              "") as string;
            const endTimeInputValue = (formData.get("endTime") ?? "") as string;

            const startTimeString =
              startTimeInputValue &&
              `${startTimeInputValue}:00.000${timezoneString}`;
            const endTimeString =
              endTimeInputValue &&
              `${endTimeInputValue}:00.000${timezoneString}`;

            const startTime =
              startTimeString && new Date(startTimeString).getTime();
            const endTime = endTimeString && new Date(endTimeString).getTime();

            updateRecord(selectedFastRecordIndex(), (prevRecord) => ({
              ...prevRecord,
              startTime: startTime || prevRecord.startTime,
              endTime: endTime || prevRecord.endTime,
            }));
            FastRecordEditModal.close();
            setSelectedFastRecordIndex(Number.MIN_SAFE_INTEGER);
          }}
        >
          <div class="form-control">
            <input
              type="hidden"
              name="timezone"
              value={-new Date().getTimezoneOffset()}
            />

            <label class="label flex gap-2">
              <span class="label-text inline-block w-16">Start time</span>
              <input
                class="grow input input-bordered input-primary invalid:input-error"
                type="datetime-local"
                name="startTime"
                max={
                  selectedFastRecord() &&
                  getDateTimeLocalInputValue(selectedFastRecord()?.endTime ?? 0)
                }
              />
            </label>

            <label class="label flex gap-2">
              <span class="label-text inline-block w-16">End time</span>
              <input
                class="grow input input-bordered input-primary invalid:input-error"
                type="datetime-local"
                name="endTime"
                max={
                  selectedFastRecord() &&
                  getDateTimeLocalInputValue(selectedFastRecord()?.endTime ?? 0)
                }
              />
            </label>
          </div>

          <div class="flex gap-4 justify-end">
            <button
              autofocus
              type="reset"
              class="btn btn-error"
              onClick={() => {
                FastRecordEditModal.close();
                setSelectedFastRecordIndex(Number.MIN_SAFE_INTEGER);
              }}
            >
              Cancel
            </button>

            <button type="submit" class="btn btn-primary">
              Edit
            </button>
          </div>
        </form>
      </FastRecordEditModal>
    </article>
  );
}
